import React from "react";

function user_policy() {
  if ("window" in global) {
    window.location.href = "/user_policy.pdf";
  }
  return <p>redirect</p>;
}

export default user_policy;
